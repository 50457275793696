<template>
  <v-container class="pb-10">
    <v-row>
      <v-col>
        <h1>관리자 - 레포팅</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="reportData != null"
      ><v-spacer></v-spacer>
      <v-col cols="auto">
        <v-card width="210mm" elevation="5">
          <v-card-title class="pa-0">
            <v-container>
              <v-row>
                <!-- <v-col cols="auto">
                  <h6 class="red--text">
                    <v-icon color="red">mdi-comment-alert</v-icon>
                    This is test data. because there is not enough data
                  </h6>
                </v-col> -->
                <v-col>
                  <v-select
                    :items="yearItems"
                    dense
                    outlined
                    hide-details
                    label="Year"
                    v-model="selectYear"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                    dense
                    outlined
                    hide-details
                    label="Month"
                    v-model="selectMonth"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-switch
                    class="pa-0 my-1 d-inline-block"
                    hide-details="auto"
                    :label="`${isClosed ? 'closeDttm' : 'openDttm'}`"
                    v-model="isClosed"
                  ></v-switch>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn color="info" @click="getData">
                    <v-icon dark size="23" class="mr-2"> mdi-magnify </v-icon>
                    검색</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn color="info" @click="doPrint">
                    <v-icon dark size="20" class="mr-2"> mdi-printer </v-icon>
                    인쇄</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
        </v-card> </v-col
      ><v-spacer></v-spacer>
    </v-row>
    <v-row v-if="reportData != null">
      <v-spacer></v-spacer>
      <v-col style="width: 210mm" cols="auto" class="px-0 pb-0 mb-5">
        <!-- <v-card width="100%" height="296mm" elevation="5" class="pa-5" id="report-page"> -->
        <v-card width="100%" elevation="5" class="pa-5" id="report-page">
          <v-container style="width: 100%">
            <v-row>
              <v-col cols="12">
                <h3 class="my-0">VAD Project Analysis</h3>
                <ul>
                  <li style="list-style-type: square">
                    Valid Projects :
                    <strong>{{ reportData.RS_VailidProjects.projects }}</strong>
                  </li>
                  <li
                    style="list-style-type: square"
                    v-for="project in reportData.RS_ExpiredProjects"
                    :key="project.year"
                  >
                    Expired Projects(In Year {{ project.year }}) :
                    <strong>{{ project.projects }}</strong>
                  </li>
                </ul>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3>VAD Case Analysis</h3>
                <ul>
                  <li style="list-style-type: square">
                    <h4>Case from Jan 1 to end of Last Month</h4>
                    <ul>
                      <li>
                        Total:
                        <strong>{{ reportData.RS_YTD_Total.YTD_Total }}</strong>
                        <ul>
                          <li>
                            L1 Only:
                            <strong>{{
                              reportData.RS_YTD_Total.YTD_L1Cases
                            }}</strong>
                          </li>
                          <li>
                            L2 :
                            <strong>{{
                              reportData.RS_YTD_Total.YTD_L2Cases
                            }}</strong>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Top Product</strong>
                        <ol>
                          <li
                            v-for="prod in reportData.RS_YTD_TopProd"
                            :key="prod.product_uuid"
                          >
                            {{ prod.product_name }} :
                            <strong>{{ prod.cases }}</strong>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <strong>Top Customers</strong>
                        <ol>
                          <li
                            v-for="Customers in reportData.RS_YTD_TopCustomer"
                            :key="Customers.company_uuid"
                          >
                            {{ Customers.company_name }} :
                            <strong>{{ Customers.cases }}</strong>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <strong>Top Month</strong>
                        <ol>
                          <li
                            v-for="Months in reportData.RS_YTD_TopMonth"
                            :key="Months.Mon"
                          >
                            {{ Months.Mon }} :
                            <strong>{{ Months.cases }}</strong>
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </li>
                  <li style="list-style-type: square" class="py-1">
                    <h4>Case Weekly Thread(from Jan 1 to end of Last Month)</h4>
                    <BarChart
                      title="Weekly Trend (YTD)"
                      :series="series"
                      :lastDate="lastDate"
                      :categories="this.categories"
                    />
                  </li>
                  <li style="list-style-type: square" class="py-2">
                    <h4>Last Month Case</h4>
                    <ul>
                      <li>
                        Total:
                        <strong>{{
                          reportData.RS_CurrentMonthCases.MTD_Total
                        }}</strong>
                        <ol>
                          <li>
                            L1 Only:
                            <strong>{{
                              reportData.RS_CurrentMonthCases.MTD_L1Cases
                            }}</strong>
                          </li>
                          <li>
                            L2:
                            <strong>{{
                              reportData.RS_CurrentMonthCases.MTD_L2Cases
                            }}</strong>
                          </li>
                          <li>
                            Technical:
                            <strong>{{
                              reportData.RS_CurrentMonthCases.MTD_Technical
                            }}</strong>
                          </li>
                          <!-- <li>
                            Fulfillment:
                            <strong>{{
                              reportData.RS_CurrentMonthCases.MTD_Fulfillment
                            }}</strong>
                          </li>
                          <li>
                            Administrative:
                            <strong>{{
                              reportData.RS_CurrentMonthCases.MTD_Administrative
                            }}</strong>
                          </li> -->
                        </ol>
                      </li>
                      <li>
                        <strong>Top3 Product</strong>
                        <ol>
                          <li
                            v-for="prod in reportData.RS_CurrentMonthCasesByProd"
                            :key="prod.product_name"
                          >
                            {{ prod.product_name }} :
                            <strong>{{ prod.cases }}</strong>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <strong>Top3 Customer</strong>
                        <ol>
                          <li
                            v-for="company in reportData.RS_CurrentMonthCasesByCustomer"
                            :key="company.company_name"
                          >
                            {{ company.company_name }} :
                            <strong>{{ company.cases }}</strong>
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-if="reportData == null">
      <v-col>
        <h2 class="text-center">데이터를 불러오고 있습니다.</h2>
        <h4 class="text-center">
          1분이상 데이터가 로드되지 않는 경우 새로고침 해 주세요.
        </h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import html2Pdf from "html2pdf.js";
import BarChart from "@/components/Charts/BarChart";
import { getReportData } from "@/api/Admin/Report";
import moment from "moment";

export default {
  components: { BarChart },
  mounted() {
    this.getData();
  },
  methods: {
    async doPrint() {
      const target = document.getElementById("report-page");
      const head = document.head;
      const newWin = window.open("", "", "width=793,height=1119");
      newWin.document.style = document.style;
      const htmltext =
        `<html>
        <head>
        ${head.innerHTML}
        </head><body>
        <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app" style="width: 210mm">` +
        target.innerHTML +
        "</div></body></html>";

      await newWin.document.write(htmltext);
      const html = newWin.document.getElementsByTagName("html")[0];
      html;
      await setTimeout(() => {
        newWin.print();
        newWin.close();
        // html2Pdf(html, {
        //   margin: 5,
        //   filename: "report.pdf",
        //   image: { type: "jpeg", quality: 1 },
        //   html2canvas: { scale: 1 },
        //   jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
        // }).then(() => {
        //   {

        //   }
        // });
      }, 1000);
    },
    async getData() {
      const endofMonth = moment()
        .year(this.selectYear)
        .month(this.selectMonth - 1)
        .endOf("month");
      this.lastDate = endofMonth.format("yyyy-MM-DD");
      const lastPlusOneDate = endofMonth.add(1, "day");
      const data = await getReportData(
        lastPlusOneDate.format("yyy-MM-DD"),
        this.isClosed ? 1 : 0
      );
      this.reportData = data;
      this.categories = this.Categories();
      const seriesData = Array(this.categories.length);
      seriesData.fill(0);
      for (let i = 0; i < this.categories.length; i++) {
        const data = this.reportData.RS_ThreeMonthWeeklyTrends.find((e) => {
          return e.start_date == this.categories[i];
        });
        if (data != null) seriesData[i] = data.cases;
      }
      this.series = [
        {
          name: "Cases",
          data: seriesData,
        },
      ];
    },
    Categories() {
      const categories = [];
      const startDate = moment(moment(this.lastDate).startOf("year"))
        .date(1)
        .day(1);
      // const endDate =
      //   this.lastDate == null ? moment().day(1) : moment(this.lastDate).day(1);
      const endDate = moment(moment(moment(this.lastDate).endOf("year"))).day(
        1
      );
      while (startDate.isBefore(endDate)) {
        categories.push(startDate.format("yyyy-MM-DD"));
        startDate.add(7, "days");
      }
      categories.push(endDate.format("yyyy-MM-DD"));
      return categories;
    },
  },
  computed: {
    yearItems() {
      const startYear = 2021;
      const currentYear = moment().year();
      const rs = [];
      for (let i = startYear; i <= currentYear; i++) {
        rs.push(i);
      }
      return rs;
    },
  },
  data() {
    return {
      reportData: null,
      categories: [],
      selectYear: moment().year(),
      selectMonth: moment().month() + 1,
      lastDate: moment().format("yyyy-MM-DD"),
      isClosed: true,
    };
  },
};
</script>
